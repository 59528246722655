
.layout-main-created{
  .header-right-content {

  }
}
.header-style {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 10px 40px;
  font-size: 24px;
  height: 70px;
  border-bottom: 1px solid #f5f5f5;
}
.header-style-about {
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-style-about .link-btn {
  display: inline-block;
  width: 100px;
  height: 30px;
  /*background-color: white;*/
  border-bottom: 2px solid #222326;
  text-align: center;
  line-height: 30px;
  margin-right: 40px;
  /*border-radius: 5px;*/
  font-weight: bold;
}
.header-style-about .link-btn:hover {
  box-shadow: 8px 0px 8px 0 rgba(34, 35, 38, .3);
}
.log-out-style {
  text-align: center;
  display: inline-block;
  width: 150px;
  height: 30px;
  line-height: 30px;
}
.log-out-style:hover {
  color: white;
  background-color: #222326;
}
.logo-style {
  width: auto;
  height: 60px;
}
.layout-header-name {
  /*margin-left: 20px;*/
  font-weight: bold;
  font-size: 18px;
}
.layout-main-created .el-card__body, .el-main {
  padding: 0;
}
.layout-main-created .layout-main-created .el-input__inner {
  width: 128px;
  /*height: 44px;*/
  border-radius: 10px;
  background: #FAF9F8;
  border: none;
}
.header-right-content {
  width: 530px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.layout-header-input {
  .el-input__inner {
    border-radius: 10px;
  }
}
.layout-header-select {
  display: flex;
}
.layout-header-right-box {
  margin-left: 10px;
  width: 139px;
  border-radius: 10px;
  background: #FAF9F8;
  border: 2px solid #EFECFD;
  /*display: flex;*/
  text-align: center;
  cursor: pointer;
}
.switch-chinese-english {
  text-align: center;
  display: flex;
  align-items: center;
}
.layout-header-balance {
  /*display: inline-block;*/
  width: 113px;
  height: 40px;
  line-height: 43px;
  font-size: 12px;
  color: #7E8597;
  display: flex;
  justify-content: center;
  align-items: center;
  /*margin-left: 10px;*/
  /*white-space: nowrap;*/
  /*overflow: hidden;*/
  /*text-overflow: ellipsis;*/
  /*padding: 0 10px;*/
}
.el-footer {
  padding: 0;
}
.el-dropdown-menu__item {
  font-size: 12px;
  color: #031425;
}
.el-dropdown-menu__item:hover {
  background: #EFECFD !important;
  color: #031425 !important;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}
.el-icon-arrow-down {
  font-size: 12px;
}
